import {Injectable} from '@angular/core';
import {WabelService} from './wabel-service.abstract';
import {GraphQLService} from './graphql-service';
import {LoadingService} from './loading.service';

import {Registration} from '../entities/registration';
import {Member} from '../entities/member';
import {Company} from '../entities/company';
import {RegistrationGraphql} from '../graphql/registration.graphql';
import {filter, map} from 'rxjs/operators';

import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone'
import {MeetingPreference} from "../entities/meeting_preference";
import {RegistrationAvailability} from "../entities/registration_availability";
import {EventParticipation} from '../entities/event_participation';
import {IdToBool} from '../entities/id_to_bool';
dayjs.extend( timezone )

@Injectable({
    providedIn: 'root'
})
export class RegistrationService extends WabelService {

    private emailRegistration: string = '';
    private zohoMember:Member = null;
    constructor(protected graphQLService: GraphQLService, protected loadingService: LoadingService) {
        super(graphQLService, loadingService);
    }

    toArrayOfObjects(objects: any[]): Registration[] {
        return objects.map((registration: any) => new Registration(registration));
    }

    toObject(object: any): Registration {
        return new Registration(object);
    }

    getEmailAfterRegistrationPopup() {
        return this.emailRegistration;
    }

    setEmailAfterRegistrationPopup(email: string) {
        if (email) {
            this.emailRegistration = email;
        }
    }
    setMemberForZohoRegistration(member:Member){
        this.zohoMember = member;
        this.zohoMember.timezone = dayjs.tz.guess();
    }
    getMemberForZohoRegistration(){
        return this.zohoMember;
    }

    initMember() {
        let member = new Member({});
        member.company = new Company({});
        member.firstname = '';
        member.name = '';
        member.email = this.getEmailAfterRegistrationPopup();
        member.jobtitle = '';
        member.mobile = '';
        member.timezone = dayjs.tz.guess();

        return member;
    }

    editSupplierRegistration(idRegistration: number, idMember: number) {
        return this.mutation(RegistrationGraphql.mutations.editSupplierRegistration, {
            idRegistration: idRegistration,
            idMember: idMember
        }).pipe(map((data) => new Registration(data.data.editSupplierRegistration)));
    }

    createRegistration(eventCode: string, idMember: number) {
        return this.mutation(RegistrationGraphql.mutations.createRegistration, {
            eventCode: eventCode,
            idMember: idMember
        }).pipe(map((data) => new Registration(data.data.createRegistration)));
    }

    sendAllBadgesByMail(idCompany: number, eventCode: string) {
        return this.mutation(RegistrationGraphql.mutations.sendAllBadgesByMail, {
            idCompany,
            eventCode
        }).pipe(map((data) => new Registration(data.data.sendAllBadgesByMail)));
    }

    removeRegistration(idRegistration: number) {
        return this.mutation(RegistrationGraphql.mutations.removeRegistration, {
            idRegistration: idRegistration
        }).pipe(map((data) => new Registration(data.data.removeRegistration)));
    }

    changeRegistrationParticipantType(idregistration: number, attendeeType: string) {
        return this.mutation(RegistrationGraphql.mutations.changeRegistrationParticipantType, {
            idregistration,
            attendeeType
        }).pipe(map(data => new Registration(data.data.changeRegistrationParticipantType)));
    }

    saveWabelEventRegistrations(eventRegistrations: any[]) {
        return this.mutation(RegistrationGraphql.mutations.saveWabelEventRegistrations, {
            eventRegistrations: eventRegistrations
        });
    }

    wabelEventRegistrationsForEvents(eventCodes: string[]) {
        return this.query(RegistrationGraphql.mutations.wabelEventRegistrationsForEvents, {
            eventCodes: eventCodes
        });
    }

    getMyRegistration(eventCode: string, fetchPolicy = 'cache-and-network') {
        return this.query(RegistrationGraphql.queries.myRegistration, {
            eventCode: eventCode
        }, fetchPolicy).pipe(filter(data => data.data !== undefined), map(data => data.data.myRegistration ? this.toObject(data.data.myRegistration) : null));
    }

    getMyRegistrationsByOrganizer(eventOrganizer: string, fetchPolicy = 'cache-and-network') {
        return this.query(RegistrationGraphql.queries.getMyRegistrationsByOrganizer, {
            eventOrganizer: eventOrganizer
        }, fetchPolicy)
            .pipe(
                map(data => this.toArrayOfObjects(data.data.myRegistrationsByOrganizer.items))
            );
    }

    updateDateLastAgendaCheck(registration: Registration) {
        return this.mutation(RegistrationGraphql.mutations.updateDateLastAgendaCheck, {
            idRegistration: registration.idcertaineventregistration
        });
    }

    updateContactDetails(idRegistration: number, firstName: string, lastName: string, phone: string, notificationPreference: string, timezone: string) {
        return this.mutation(RegistrationGraphql.mutations.updateContactDetails, {
            idRegistration: idRegistration,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            notificationPreference: notificationPreference,
            timezone: timezone
        }).pipe(map(data => new Registration(data.data.updateContactDetails)));
    }

    updateWorkshopsPreferences(registration: Registration, idWorkshops: number[], idCompanies: number[]) {
        const conferencePreferences = idWorkshops.map(id => ({idregistration: registration.idcertaineventregistration, idworkshop: id}));
        const conferenceMeetingPreferences = idCompanies.map(id => ({idregistration: registration.idcertaineventregistration, idcompany: id}));
        return this.mutation(RegistrationGraphql.mutations.updateWorkshopsPreferences, {
            idRegistration: registration.idcertaineventregistration,
            eventCode: registration.event.eventCode,
            conferenceMeetingPreferences: conferenceMeetingPreferences,
            conferencePreferences: conferencePreferences
        }).pipe(map((data) => new Registration(data.data.updateWorkshopsPreferences)));
    }

    updateMeetingPreference(registration: Registration, eventCode: string, idcompany: number, type: string, removedReason: string = null, customRemovedReason: string = null) {
        return this.mutation(RegistrationGraphql.mutations.updateMeetingPreference, {
            idRegistration: registration.idcertaineventregistration,
            eventCode: eventCode,
            type: type,
            idcompany: idcompany,
            removedReason: removedReason,
            customRemovedReason: customRemovedReason,
        }).pipe(map((data) => new MeetingPreference(data.data.updateMeetingPreference)));
    }

    updateMeetingPreferences(registration: Registration, selections: MeetingPreference[] = null, importedPreferencesToRemove: MeetingPreference[] = null) {
        return this.mutation(RegistrationGraphql.mutations.updateMeetingPreferences, {
            idRegistration: registration.idcertaineventregistration,
            selections: selections.map(meetPref => ({
                idregistration: registration.idcertaineventregistration,
                idcompany: meetPref.company.idcompany,
                status: meetPref.status,
                removedReason: meetPref.removedReason,
                customRemovedReason: meetPref.customRemovedReason
            })),
            importedPreferencesToRemove: importedPreferencesToRemove.map(meetPref => ({
                idregistration: registration.idcertaineventregistration,
                idcompany: meetPref.company.idcompany,
                status: meetPref.status,
                removedReason: meetPref.removedReason,
                customRemovedReason: meetPref.customRemovedReason
            }))
        }).pipe(map((data) => new MeetingPreference(data.data.updateMeetingPreferences)));
    }

    saveMultipleAvailabilities(registration: Registration, availabilities: any[]) {
        return this.mutation(RegistrationGraphql.mutations.saveMultipleAvailabilities, {
            idregistration: registration.idcertaineventregistration,
            eventCode: registration.event.eventCode,
            registrationAvailabilities: availabilities,
        })
            .pipe(
                filter(data => data.data !== undefined),
                map(data => data.data.saveMultipleAvailabilities && data.data.saveMultipleAvailabilities.items ? data.data.saveMultipleAvailabilities.items.map((ra: any) => new RegistrationAvailability(ra)) : [])
            );
    }

    getMyCompleteSelection(eventCode: string, fetchPolicy = 'cache-and-network') {
        return this.query(RegistrationGraphql.queries.myCompleteSelection, {
            eventCode: eventCode
        }, fetchPolicy).pipe(filter(data => data.data !== undefined), map(data => data.data.myRegistration ? this.toObject(data.data.myRegistration) : null));
    }

    askTeamScheduleMeeting(eventCode: string, company: Company) {
        return this.mutation(RegistrationGraphql.mutations.askTeamScheduleMeeting, {
            eventCode,
            idcompany: company.idcompany,
        })
            .pipe(
                map(data => data.data.askTeamScheduleMeeting)
            );
    }

    getMyRegistrationMeeting(eventCode: string, fetchPolicy = 'cache-and-network') {
        return this.query(RegistrationGraphql.queries.myRegistrationMeetingList, {
            eventCode: eventCode
        }, fetchPolicy).pipe(filter(data => data.data !== undefined), map(data => data.data.myRegistration ? this.toObject(data.data.myRegistration) : null));

    }

    saveCompanyBookletDescription(eventCode: string, idcompany: number, content: string) {
        return this.mutation(RegistrationGraphql.mutations.saveCompanyBookletDescription, {
            eventCode,
            idcompany,
            content
        })
            .pipe(
                map(data => new EventParticipation(data.data.saveCompanyBookletDescription))
            );
    }

    saveMostInnovativeProductDescription(eventCode: string, idcompany: number, content: string) {
        return this.mutation(RegistrationGraphql.mutations.saveMostInnovativeProductDescription, {
            eventCode,
            idcompany,
            content
        })
            .pipe(
                map(data => new EventParticipation(data.data.saveMostInnovativeProductDescription))
            );
    }

    getMyRegistrationRetailTourParticipation(eventCode: string) {
        return this.query(RegistrationGraphql.queries.myRegistrationRetailTourParticipation, {
            eventCode
        }).pipe(map(data => data.data.myRegistration ? this.toObject(data.data.myRegistration) : null));
    }

    getCompanyRegistrationsRetailTourParticipation(eventCode: string) {
        return this.query(RegistrationGraphql.queries.companyRegistrationsRetailTourParticipation, {
            eventCode
        }).pipe(map(data => this.toArrayOfObjects(data.data.me.company.registrationsForEvent)));
    }

    saveRetailTourParticipation(eventCode: string, idToBool: IdToBool[]) {
        return this.mutation(RegistrationGraphql.mutations.saveRetailTourParticipation, {
            eventCode,
            idToBool
        })
            .pipe(
                map(data => this.toArrayOfObjects(data.data.saveRetailTourParticipation))
            );
    }
}
