import { WabelEntity } from "./wabel-entity.abstract";
import { Product } from "./product";
import { Member } from "./member";
import { CompanyMembership } from "./company_membership";
import { Citylocation } from "./citylocation";
import { CompanyTypes } from "../company_types";
import { PurchasingGroupStoresCountryDetails } from "./purchasing_group_stores_country_details";
import { CompanyFile } from "./company_file";
import { CompanyType } from "./company_type";
import { PredefinedMessagesTopics } from "./predefined_messages_topics";
import { FileToUpload } from "./fileToUpload";
import { FilePreviewableInterface } from "./file-previewable.interface";
import { CompanyRelationship } from "./company_relationship";
import { Registration } from "./registration";
import { EventParticipation } from "./event_participation";
import { Summit } from "./summit";
import { EcoPackProject } from "./eco-pack-project";
import { Tag } from "./tag";
import { CustomTag } from "./custom_tag";
import { CompanyProfileSubmission } from "./company_profile_submission";
import { Institution } from "./institution";
import {PrivateMessageThread} from "./private_message_thread";
import {EventMeetingConfirmation} from "./event_meeting_confirmation";
import {Meeting} from "./meeting";
import {CompanyVideo} from "./company_video";
import {Score} from './score';
import {BusinessMapping} from "./business_mapping";
import {CompanyClient} from './company_client';
import {SourcingProject} from './sourcing_project';

// TODO: REMOVE WHEN ECOPACK PROJECT TAGS ARE MIGRATED TO TAGS
export class EcoPackProjectTag {
    constructor(obj: any) {
        if (obj) {
            if (obj.idecopackprojecttag) {
                this.idTag = obj.idecopackprojecttag;
            }
            if (obj.idparent) {
                this.idParent = obj.idparent;
            }
            if (obj.name) {
                this.name = obj.name;
            }
        }
    }

    idTag: string;
    idParent: number;
    name: string;
}

export class Company extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            if (obj.companyProfileSubmissionsByCreatedByCompany && obj.companyProfileSubmissionsByCreatedByCompany.items) {
                this.companyProfileSubmissionsByCreatedByCompany = obj.companyProfileSubmissionsByCreatedByCompany.items.map(item => new CompanyProfileSubmission(item));
            }
            this.ecopackprojects = obj.ecopackprojects ? obj.ecopackprojects.map(project => new EcoPackProject(project)) : [];
            if (obj.ecoPackProjects) {
                this.ecopackprojects = obj.ecoPackProjects.items;
            }
            this.ecoPackProjectsCount = obj.ecoPackProjects && obj.ecoPackProjects.count ? obj.ecoPackProjects.count : null;
            this.company_type_machine_name = obj.company_type_machine_name ? obj.company_type_machine_name : null;
            this.idcompany = obj.idcompany ? +obj.idcompany : null; // force id to number
            this.parentCompany = obj.parentCompany ? new Company(obj.parentCompany) : null;
            this.companytype = obj.companytype ? new CompanyType(obj.companytype) : null;
            this.citylocation = obj.citylocation ? new Citylocation(obj.citylocation) : null;
            this.country = obj.country ? new Tag(obj.country) : null;
            this.memberships = obj.memberships ? obj.memberships.map(membership => new CompanyMembership(membership)) : null;
            this.activeMemberships = obj.activeMemberships ? obj.activeMemberships.map(membership => new CompanyMembership(membership)) : null;
            this.members = obj.allMembers ? obj.allMembers.map(member => new Member(member)) : obj.validatedMembers && obj.validatedMembers.items ? obj.validatedMembers.items.map(member => new Member(member)) : obj.onMembers ? obj.onMembers.map(member => new Member(member)) : null;
            this.buyingOffices = obj.buyingOffices ? obj.buyingOffices.map(company => new Company(company)) : null;
            this.purchasingGroupStoresCountryDetails = obj.purchasingGroupStoresCountryDetails ? obj.purchasingGroupStoresCountryDetails.map(item => new PurchasingGroupStoresCountryDetails(item)) : [];
            this.relativeCompaniesInCountry = obj.relativeCompaniesInCountry ? obj.relativeCompaniesInCountry.map(company => new Company(company)) : null;
            this.files = obj.files ? obj.files.map(file => new CompanyFile(file)) : [];
            this.companyImages = obj.companyImages ? obj.companyImages.map(companyImage => new CompanyFile(companyImage)) : [];
            this.ecoPackPolicyFeatures = obj.ecoPackPolicyFeatures && obj.ecoPackPolicyFeatures.items ? obj.ecoPackPolicyFeatures.items.map(feature => new Tag(feature)) : [];
            this.certifications = obj.certifications && obj.certifications.items ? obj.certifications.items.map(certification => new Tag(certification)) : [];
            this.customCertifications = obj.customCertifications && obj.customCertifications.items ? obj.customCertifications.items.map(certification => new CustomTag(certification)) : [];
            this.activities = obj.activities && obj.activities.items ? obj.activities.items.map(activity => new Tag(activity)) : [];
            this.activities = obj.activitiesOfMembers ? obj.activitiesOfMembers.map(activity => new Tag(activity)) : this.activities;
            this.exportCountries = obj.exportCountries ? obj.exportCountries.map(country => new Tag(country)) : [];
            this.products = obj.products ? obj.products.map(product => new Product(product)) : [];
            this.similarCompanies = obj.similarCompanies ? obj.similarCompanies.map(similarCompany => new Company(similarCompany)) : [];
            this.predefinedMessagesTopics = obj.predefinedMessagesTopics ? obj.predefinedMessagesTopics.map(topic => new PredefinedMessagesTopics(topic)) : [];
            this.banners = obj.banners && obj.banners.items ? obj.banners.items.map(company => new Company(company)) : [];
            this.relationships = obj.relationships ? obj.relationships.map(relationship => new CompanyRelationship(relationship)) : [];
            this.potentialSuppliersInterested = obj.potentialSuppliersInterested && obj.potentialSuppliersInterested.items ? obj.potentialSuppliersInterested.items.map(company => new Company(company)) : [];
            this.potentialBuyersInterested = obj.potentialBuyersInterested && obj.potentialBuyersInterested.items ? obj.potentialBuyersInterested.items.map(company => new Company(company)) : [];
            this.registrationsForSummit = obj.registrationsForSummit ? obj.registrationsForSummit.map(registration => new Registration(registration)) : [];
            this.registrationsForEvent = obj.registrationsForEvent ? obj.registrationsForEvent.map(registration => new Registration(registration)) : [];
            this.consultantCsm = obj.consultantCsm ? new Member(obj.consultantCsm) : null;
            this.participationBySummitCode = obj.participationBySummitCode ? new EventParticipation(obj.participationBySummitCode) : null;
            this.participationByEventCode = obj.participationByEventCode ? new EventParticipation(obj.participationByEventCode) : null;
            this.summitsParticipation = obj.summitsParticipation ? obj.summitsParticipation.map(summit => new Summit(summit)) : [];
            this.specializations = obj.specializations && obj.specializations.items ? obj.specializations.items.map(specialization => new Tag(specialization)) : [];
            this.companyTags = obj.companyTags ? obj.companyTags.map(tag => new Tag(tag)) : [];
            this.minimumOrderQuantities = obj.minimumOrderQuantities && obj.minimumOrderQuantities.items ? obj.minimumOrderQuantities.items.map(minimumOrderQuantity => new Tag(minimumOrderQuantity)) : [];
            this.institutions = obj.institutions && obj.institutions.items ? obj.institutions.items.map(x => new Institution(x)) : [];
            this.pointOfSales = obj.pointOfSales && obj.pointOfSales.items ? obj.pointOfSales.items.map(pointOfSale => new Tag(pointOfSale)) : (obj.pointOfSales && obj.pointOfSales.length ? obj.pointOfSales.map(pointOfSale => new Tag(pointOfSale)) : []);
            this.exportPositions = obj.exportPositions && obj.exportPositions.items ? obj.exportPositions.items.map(exportPosition => new Tag(exportPosition)) : [];
            this.eventParticipations = obj.eventParticipations ? obj.eventParticipations.map(ep => new EventParticipation(ep)) : [];
            this.companyDistributionChannelsReference = obj.companyDistributionChannelsReference && obj.companyDistributionChannelsReference.items ? obj.companyDistributionChannelsReference.items.map(companyDistributionChannelReference => new Tag(companyDistributionChannelReference)) : [];
            this.countriesWorkingWith = obj.countriesWorkingWith ? obj.countriesWorkingWith.map(c => new Tag(c)) : [];
            this.targetConsumers = obj.targetConsumers && obj.targetConsumers.items ? obj.targetConsumers.items.map(targetConsumer => new Tag(targetConsumer)) : [];
            this.rangeOfProducts = obj.rangeOfProducts && obj.rangeOfProducts.items ? obj.rangeOfProducts.items.map(rangeOfProduct => new Tag(rangeOfProduct)) : [];
            this.nutritionalInformations = obj.nutritionalInformations && obj.nutritionalInformations.items ? obj.nutritionalInformations.items.map(nutritionalInformation => new Tag(nutritionalInformation)) : [];
            this.qualityLabels = obj.qualityLabels && obj.qualityLabels.items ? obj.qualityLabels.items.map(qualityLabel => new Tag(qualityLabel)) : [];
            this.exportCertifications = obj.exportCertifications && obj.exportCertifications.items ? obj.exportCertifications.items.map(exportCertification => new Tag(exportCertification)) : [];
            this.stockAvailabilityLastUpdate = obj.stockAvailabilityLastUpdate ? new Date(obj.stockAvailabilityLastUpdate) : null;
            this.activeAndAlreadyLoggedMembers = obj.activeAndAlreadyLoggedMembers && obj.activeAndAlreadyLoggedMembers.items ? obj.activeAndAlreadyLoggedMembers.items.map(m => new Member(m)) : [];
            this.countActiveAndAlreadyLoggedMembers = obj.activeAndAlreadyLoggedMembers && obj.activeAndAlreadyLoggedMembers.count ? obj.activeAndAlreadyLoggedMembers.count : null;
            this.searchResultVideoThreadid = obj.preventFromVideoRequest && obj.preventFromVideoRequest.idthread ? obj.preventFromVideoRequest.idthread : null;
            this.searchResultVideoRequestPrevent = obj.preventFromVideoRequest && obj.preventFromVideoRequest.prevent ? obj.preventFromVideoRequest.prevent : null;
            this.categories = obj.bestCategories && obj.bestCategories.items ? obj.bestCategories.items.map(category => new Tag(category)) : [];
            this.categories = obj.productCategories && obj.productCategories.items ? obj.productCategories.items.map(category => new Tag(category)) : this.categories;
            this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : this.categories;
            this.buyingScope = obj.buyingScope && obj.buyingScope.items ? obj.buyingScope.items.map(category => new Tag(category)) : [];
            this.priceRanges = obj.priceRanges && obj.priceRanges.items ? obj.priceRanges.items.map(priceRange => new Tag(priceRange)) : [];
            this.productsPriceRanges = obj.productsPriceRanges && obj.productsPriceRanges.items ? obj.productsPriceRanges.items.map(priceRange => new Tag(priceRange)) : [];
            this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
            this.originsOfMainIngredients = obj.originsOfMainIngredients?.items?.map(country => new Tag(country)) ?? [];
            this.productAttributes = obj.productAttributes?.items?.map(tag => new Tag(tag)) ?? [];
            this.productsInFavorites = obj.productsInFavorites?.items ? obj.productsInFavorites.items.map(product => new Product(product)) : [];
            this.ecoPackProjectCategories = obj.ecoPackProjectCategories && obj.ecoPackProjectCategories.items ? obj.ecoPackProjectCategories.items.map(category => new EcoPackProjectTag(category)) : [];
            this.ecoPackProjectEcoFriendlyFeatures = obj.ecoPackProjectEcoFriendlyFeatures && obj.ecoPackProjectEcoFriendlyFeatures.items ? obj.ecoPackProjectEcoFriendlyFeatures.items.map(ecoFriendlyFeature => new EcoPackProjectTag(ecoFriendlyFeature)) : [];
            this.nbSellingPoints = obj.nbSellingPoints ? new Tag(obj.nbSellingPoints) : null;
            this.numemployees = obj.numemployees ? new Tag(obj.numemployees) : null;
            this.ca = obj.ca ? new Tag(obj.ca) : null;
            this.visitsForAnalyticsDashboard = obj.visitsForAnalyticsDashboard && typeof obj.visitsForAnalyticsDashboard === 'string' ? JSON.parse(obj.visitsForAnalyticsDashboard) : [];
            this.searchApparitionsForAnalyticsDashboard = obj.searchApparitionsForAnalyticsDashboard && typeof obj.searchApparitionsForAnalyticsDashboard === 'string' ? JSON.parse(obj.searchApparitionsForAnalyticsDashboard) : [];
            this.totalVisitsForAnalyticsDashboard = obj.totalVisitsForAnalyticsDashboard && typeof obj.totalVisitsForAnalyticsDashboard === 'string' ? JSON.parse(obj.totalVisitsForAnalyticsDashboard) : null;
            this.totalSearchApparitionsForAnalyticsDashboard = obj.totalSearchApparitionsForAnalyticsDashboard && typeof obj.totalSearchApparitionsForAnalyticsDashboard === 'string' ? JSON.parse(obj.totalSearchApparitionsForAnalyticsDashboard) : null;
            this.top3MostViewedProductsForAnalyticsDashboard = obj.top3MostViewedProductsForAnalyticsDashboard && typeof obj.top3MostViewedProductsForAnalyticsDashboard === 'string' ? JSON.parse(obj.top3MostViewedProductsForAnalyticsDashboard) : [];
            this.overviewCountryForAnalyticsDashboard = obj.overviewCountryForAnalyticsDashboard && typeof obj.overviewCountryForAnalyticsDashboard === 'string' ? JSON.parse(obj.overviewCountryForAnalyticsDashboard) : [];
            this.overviewCompanyTypeForAnalyticsDashboard = obj.overviewCompanyTypeForAnalyticsDashboard && typeof obj.overviewCompanyTypeForAnalyticsDashboard === 'string' ? JSON.parse(obj.overviewCompanyTypeForAnalyticsDashboard) : [];
            this.overviewTurnoverForAnalyticsDashboard = obj.overviewTurnoverForAnalyticsDashboard && typeof obj.overviewTurnoverForAnalyticsDashboard === 'string' ? JSON.parse(obj.overviewTurnoverForAnalyticsDashboard.replace(/millions|million/g, '')) : [];
            this.lastInterestedVisitorsForAnalyticsDashboard = obj.lastInterestedVisitorsForAnalyticsDashboard && typeof obj.lastInterestedVisitorsForAnalyticsDashboard === 'string' ? JSON.parse(obj.lastInterestedVisitorsForAnalyticsDashboard) : [];
            this.marketByCompanyTypeForAnalyticsDashboard = obj.marketByCompanyTypeForAnalyticsDashboard && typeof obj.marketByCompanyTypeForAnalyticsDashboard === 'string' ? JSON.parse(obj.marketByCompanyTypeForAnalyticsDashboard) : [];
            this.marketByCountryForAnalyticsDashboard = obj.marketByCountryForAnalyticsDashboard && typeof obj.marketByCountryForAnalyticsDashboard === 'string' ? JSON.parse(obj.marketByCountryForAnalyticsDashboard) : [];
            this.marketByTurnoverForAnalyticsDashboard = obj.marketByTurnoverForAnalyticsDashboard && typeof obj.marketByTurnoverForAnalyticsDashboard === 'string' ? JSON.parse(obj.marketByTurnoverForAnalyticsDashboard.replace(/millions|million/g, '')) : [];
            this.companyProductsTags = obj.companyProductsTags && obj.companyProductsTags.items ? obj.companyProductsTags.items.map(tag => new Tag(tag)) : [];
            this.marketMostSearchedProductsCategoriesForAnalyticsDashboard = obj.marketMostSearchedProductsCategoriesForAnalyticsDashboard  && typeof obj.marketMostSearchedProductsCategoriesForAnalyticsDashboard === 'string' ? JSON.parse(obj.marketMostSearchedProductsCategoriesForAnalyticsDashboard) : [];
            this.sustainability = obj.sustainability && obj.sustainability.items ? obj.sustainability.items.map(sustainability => new Tag(sustainability)) : [];
            this.healthAndWellness = obj.healthAndWellness && obj.healthAndWellness.items ? obj.healthAndWellness.items.map(healthAndWellness => new Tag(healthAndWellness)) : [];
            this.consumptionMode = obj.consumptionMode && obj.consumptionMode.items ? obj.consumptionMode.items.map(consumptionMode => new Tag(consumptionMode)) : [];
            this.dietary = obj.dietary && obj.dietary.items ? obj.dietary.items.map(dietary => new Tag(dietary)) : [];
            this.temperatureControlled = obj.temperatureControlled && obj.temperatureControlled.items ? obj.temperatureControlled.items.map(temperatureControlled => new Tag(temperatureControlled)) : [];
            this.productCategoriesInRedisForMyMarketAnalytics = obj.productCategoriesInRedisForMyMarketAnalytics && obj.productCategoriesInRedisForMyMarketAnalytics.items ? obj.productCategoriesInRedisForMyMarketAnalytics.items.map(tag => new Tag(tag)) : [];
            this.marketingFocus = obj.marketingFocus && obj.marketingFocus.items ? obj.marketingFocus.items.map(v => new Tag(v)) : [];
            this.companyCountriesDistribution = obj.companyCountriesDistribution && obj.companyCountriesDistribution.items ? obj.companyCountriesDistribution.items.map(comp => new Tag(comp)) : [];
            this.logo = obj.logo ? new CompanyFile(obj.logo) : null;
            this.meetingWithMyCompany = obj.meetingWithMyCompany ? new Meeting(obj.meetingWithMyCompany) : null;
            this.myEventMeetingConfirmation = obj.myEventMeetingConfirmation ? new EventMeetingConfirmation(obj.myEventMeetingConfirmation) : null;
            this.companyVideos = obj.companyVideos && obj.companyVideos.items ? obj.companyVideos.items.map(companyVideo => new CompanyVideo(companyVideo)) : [];
            this.businessMappings = obj.businessMappings && obj.businessMappings.items ? obj.businessMappings.items.map(businessMapping => new BusinessMapping(businessMapping)) : [];
            this.businessMappingPrioritiesCount = obj.businessMappingPriorities && obj.businessMappingPriorities.count ? obj.businessMappingPriorities.count : null;
            this.currency = obj.currency ? new Tag(obj.currency) : null;
            this.certificationsFiles = obj.certificationsFiles ? obj.certificationsFiles.map(file => new CompanyFile(file)) : [];
            this.companyRewardScore = obj.companyRewardScore ? new Score(obj.companyRewardScore) : null;
            this.companyRequiredInfoScore = obj.companyRequiredInfoScore ? new Score(obj.companyRequiredInfoScore) : null;
            this.businessPriorityScore = obj.businessPriorityScore ? new Score(obj.businessPriorityScore) : null;
            this.companyClients = obj.companyClients && obj.companyClients.items ? obj.companyClients.items.map(companyClient => new CompanyClient(companyClient)) : [];
            this.companyClientCount = obj.companyClients && obj.companyClients.count ? obj.companyClients.count : null;
            this.lastCompanyProfileSubmissionToCompany = obj.lastCompanyProfileSubmissionToCompany ? new CompanyProfileSubmission(obj.lastCompanyProfileSubmissionToCompany) : null;
            this.meetingsWithCurrentMember = obj.meetingsWithCurrentMember && obj.meetingsWithCurrentMember.items ? obj.meetingsWithCurrentMember.items.map(meeting => new Meeting(meeting)) : [];
            this.sourcingProjectsAsProspects = obj.sourcingProjectsAsProspects?.items ? obj.sourcingProjectsAsProspects.items.map(sourcing => new SourcingProject(sourcing)) : [];
        }
    }

    zohoId: string;
    idcompany: number;
    companytype: CompanyType;
    name: string;
    type: string;
    company_type_machine_name: string;
    status: string;
    urlname: string;
    description: string;
    bookletDescription: string;
    website: string;
    logoUrl: string;
    logo: CompanyFile;
    urlPath: string;
    phone: string;
    ca: Tag;
    numemployees: Tag;
    nbStores: number;
    completionPercentage: number;
    citylocation: Citylocation;
    products: Product[] = [];
    productsInFavorites: Product[] = [];
    ecopackpolicydescription: string;
    ecopackprojects: EcoPackProject[] = [];
    ecoPackProjectsNumber: number;
    categories: Tag[];
    bestCategories: Tag[];
    buyingScope: Tag[];
    companyCountriesDistribution: Tag[];
    priceRanges: Tag[];
    productsPriceRanges: Tag[];
    eventParticipations: EventParticipation[];
    members: Member[];
    parentCompany: Company;
    buyingOffices: Company[];
    purchasingGroups: Company[];
    country: Tag;
    originsOfMainIngredients: Tag[];
    productAttributes: Tag[];
    countriesWorkingWith: Tag[] = [];
    isWabelClubMember: boolean;
    membership: boolean;
    memberships: CompanyMembership[];
    activeMemberships: CompanyMembership[];
    formattedAddress: string;
    purchasingGroupStoresCountryDetails: PurchasingGroupStoresCountryDetails[];
    relativeCompaniesInCountry: Company[];
    privateLabel: string;
    yearEstablished: number;
    logisticsCapabilities: string;
    privateLabelParts: number;
    productionCapacity: string;
    ecoPackPolicyFeatures: Tag[];
    certifications: Tag[];
    customCertifications: CustomTag[];
    specializations: Tag[];
    activities: Tag[];
    companyDistributionChannelsReference: Tag[];
    exportPositions: Tag[];
    rootCategories: Tag[];
    regionName: string;
    cityName: string;
    postalCode: string;
    nbSellingPoints: Tag;
    predefinedMessagesTopics: PredefinedMessagesTopics[];
    exportCountries: Tag[];
    similarCompanies: Company[];
    consultant: Member;
    files: CompanyFile[] = [];
    companyImages: CompanyFile[] = [];
    currentCompanyLogo: FilePreviewableInterface[] = [];
    banners: Company[];
    hasSentAtLeastOneMassage: boolean;
    hasMadeAtLeastOneSearchOnWeb: boolean;
    hasFilledOutSourcingPriorities: boolean;
    searchOnProducts: number;
    oldSearchOnProducts: number;
    productSamplesAsked: number;
    oldProductSamplesAsked: number;
    contactingRequests: number;
    oldContactingRequests: number;
    totalFavoritesOnCompany: number;
    oldTotalFavoritesOnCompany: number;
    oldSearchOnCompany: number;
    searchOnCompany: number;
    relationships: CompanyRelationship[] = [];
    potentialSuppliersInterested: Company[] = [];
    potentialBuyersInterested: Company[] = [];
    registrationsForSummit: Registration[] = [];
    registrationsForEvent: Registration[] = [];
    consultantCsm: Member;
    participationBySummitCode: EventParticipation;
    participationByEventCode: EventParticipation;
    hasParticipationForEvent: boolean;
    summitsParticipation: Summit[];
    companyTags: Tag[];
    minimumOrderQuantities: Tag[];
    institutions: Institution[] = [];
    numberOfCountriesOfPresence: number;
    countOnMembers: number;
    nbCountryOfactivity: number;
    stockAvailability: string;
    stockAvailabilityLastUpdate: Date;
    targetConsumers: Tag[];
    rangeOfProducts: Tag[];
    nutritionalInformations: Tag[];
    qualityLabels: Tag[];
    exportCertifications: Tag[];
    linkedin: string;
    instagram: string;
    facebook: string;
    twitter: string;
    pinterest: string;
    canSubmitProfile: boolean;
    onBoardingFinished: boolean;
    onBoardingHasProducts: boolean;
    onBoardingHasEcoProjects: boolean;
    onBoardingCompletedEcoPackPolicy: boolean;
    onBoardingHasRelationshipWithBuyer: boolean;
    onBoardingNeedlCompanyProfileCompletionStep: string;
    onBoardingHasCompletedNeedlCompanyProfile: boolean;
    pointOfSales: Tag[];
    ecoPackProjectsCount: number;
    hasBuyerForCurrentMemberCategories: boolean;
    supplierWantsToSellInMyCountry: boolean;
    supplierSellsInMyCountry: boolean;
    registrationStep: string;
    lastUpdate: string;

    // Fields used only for uploading files
    newCompanyLogo: FileToUpload[] = [];
    newCompanyImages: FileToUpload[] = [];
    newCompanyFiles: FileToUpload[] = [];

    activeAndAlreadyLoggedMembers: Member[];
    countActiveAndAlreadyLoggedMembers: number;
    searchResultVideoRequestPrevent: boolean;
    searchResultVideoThreadid: number;

    companyProfileSubmissionsByCreatedByCompany: CompanyProfileSubmission[];
    ecoPackProjectEcoFriendlyFeatures: EcoPackProjectTag[];
    ecoPackProjectCategories: EcoPackProjectTag[];
    hasVideoMeetingsWithCurrentMember: boolean;
    threadWithCurrentMember: PrivateMessageThread;
    hasPendingVideoMeetingsWithCurrentMember: boolean;

    visitsForAnalyticsDashboard: any[];
    searchApparitionsForAnalyticsDashboard: any[];
    totalVisitsForAnalyticsDashboard: any;
    totalSearchApparitionsForAnalyticsDashboard: any;
    top3MostViewedProductsForAnalyticsDashboard: any[];
    overviewCountryForAnalyticsDashboard: any[];
    overviewCompanyTypeForAnalyticsDashboard: any[];
    overviewTurnoverForAnalyticsDashboard: any[];
    lastInterestedVisitorsForAnalyticsDashboard: any[];
    rankVisibilityCategoriesPercentageAnalyticsDashboard: number;
    rankVisibilityCountryPercentageAnalyticsDashboard: number;

    marketByCompanyTypeForAnalyticsDashboard: any[];
    marketByCountryForAnalyticsDashboard: any[];
    marketByTurnoverForAnalyticsDashboard: any[];
    marketMostSearchedProductsCategoriesForAnalyticsDashboard: any[];
    companyProductsTags: Tag[];
    sustainability: Tag[];
    healthAndWellness: Tag[];
    consumptionMode: Tag[];
    dietary: Tag[];
    temperatureControlled: Tag[];

    hasActiveMembersLastMonthForAnalyticsDashboard: boolean;
    productCategoriesInRedisForMyMarketAnalytics: Tag[];

    marketingFocus: Tag[];

    meetingWithMyCompany: Meeting;
    myEventMeetingConfirmation: EventMeetingConfirmation;
    companyVideos: CompanyVideo[];
    productCount: number;
    businessMappings: BusinessMapping[];
    businessMappingPrioritiesCount: number;
    valueProposition: string;

    currency: Tag;
    appliedDiscount: number;
    remainingProductRequests: number;
    remainingCategoryBenchmarks: number;
    remainingCompanyProfileSubmissions: number;

    certificationsFiles: CompanyFile[] = [];
    companyRewardScore: Score;
    companyRequiredInfoScore: Score;
    businessPriorityScore: Score;
    hasAllRequiredProfileFields: boolean;

    hasAddedAllProducts: boolean;
    hasAddedAllClients: boolean;
    hasAddedAllEcoPackProjects: boolean;
    companyClients: CompanyClient[];
    companyClientCount: number;
    canBeAddedAsPreference: boolean;
    attributesAreMatching: boolean;
    certificationsAreMatching: boolean;
    categoryIsMatching: boolean;
    activitiesAreMatching: boolean;
    lastCompanyProfileSubmissionToCompany: CompanyProfileSubmission;
    isFavoriteForCurrentMember: boolean;
    companyHasCurrentMemberCompanyAsSourcingRequestRecommendation: boolean;
    companyHasCurrentMemberCompanyAsSourcingNeedRecommendation: boolean;
    isCompanyInCurrentMemberBusinessMappingRecommendations: boolean;
    meetingsWithCurrentMember: Meeting[];
    countriesNumber: number;
    bannersNumber: number;

    hasCertifications: boolean;
    turnoverYear: number;
    hasVideoMeetingRequestWithMe: boolean;
    hasVideoMeetingWithMe: boolean;
    sourcingProjectsAsProspects: SourcingProject[];
    hasEventParticipationsThisYear: boolean;
    isInCatalog: boolean;

    get id(): number {
        return +this.idcompany;
    }

    set id(id: number) {
        this.idcompany = id;
    }

    get fmcgProducts() {
        return this.products.filter(p => !p.isPackagingProduct);
    }

    get packagingProducts() {
        return this.products.filter(p => p.isPackagingProduct);
    }

    isAdmin(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.ADMIN;
    }

    isRetailGroup(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.RETAIL_GROUP;
    }

    isBuyingOffice(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.BUYING_OFFICE;
    }

    isManufacturer(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.MANUFACTURER;
    }

    isServiceProvider(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.SERVICE_PROVIDER;
    }


    isPackagingSupplier(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.Packaging_supplier;
    }

    get isPackaging() {
        return this.isPackagingSupplier();
    }
    isImporter(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.IMPORTER;
    }

    isFoodService(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.FOOD_SERVICE;
    }

    isCoPacking(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.CO_PACKING;
    }

    isBuyingAlliance(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.BUYING_ALLIANCE;
    }

    isExpert(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.EXPERT;
    }

    isFederation(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.FEDERATION;
    }

    isPress(): boolean {
        return this.company_type_machine_name && this.company_type_machine_name === CompanyTypes.PRESS;
    }

    getTypeOfCompany(): string {
        switch (this.company_type_machine_name) {
            case "manufacturer":
            case "packaging":
                return 'supplier';
            case "admin":
                return 'supplier';
            case "food_service":
            case "retail_group":
            case "buying_office":
            case "buying_alliance":
            case "co_packer":
            case "banner":
                return 'buyer';
            case "importer":
                return 'importer';
            case "service_provider":
                return 'service_provider';
            default:
                return 'other';
        }
    }

    isSupplier(): boolean {
        return this.getTypeOfCompany() === 'supplier' || this.isServiceProvider();
    }

    isBuyer(): boolean {
        return this.getTypeOfCompany() === 'buyer' || this.isImporter();
    }

    inAnyInstitution(): boolean {
        return this.institutions.length > 0;
    }

    deleteProduct(id: number) {
        const index = this.products.map(element => element.id).indexOf(id);
        if (index === -1) {
            return;
        }
        this.products.splice(index, 1);
    }
}

export const LINKEDIN_BASE_PROFILE_URL = 'https://linkedin.com/company/';
export const FACEBOOK_BASE_PROFILE_URL = 'https://facebook.com/';
export const INSTAGRAM_BASE_PROFILE_URL = 'https://instagram.com/';
export const TWITTER_BASE_PROFILE_URL = 'https://twitter.com/';
export const PINTEREST_BASE_PROFILE_URL = 'https://pinterest.com/';
