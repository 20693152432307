import gql from "graphql-tag";
import { TagTypeService } from "../services/tag_type.service";
import { TagTypeGraphql } from "./tag_type.graphql";

export class CompanyGraphQL {
    static fragments = {
        light: gql`
            fragment lightCompany on Company {
                idcompany,
                name,
                type,
                status
                zohoId
            }
        `,

        full: gql`
            fragment fullCompany on Company {
                idcompany,
                name,
                type,
                status
                country {
                    name
                },
                phone,
                website,
                description,
                yearEstablished,
                numemployees {
                    ...tag
                },
                ca {
                    ...tag
                },
                logisticsCapabilities,
                privateLabelParts,
                productionCapacity,
                memberships {
                    idsubscription,
                    status,
                    startDate,
                    endDate
                },
                products(published: true) {
                    logo {
                        imageUrl
                    }
                    iduserproduct
                },
                categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                    items {
                        ...fullTag
                    }
                },
                eventParticipations {
                    certainEvent {
                        eventCode
                    }
                },
                parentCompany {
                    idcompany,
                    name,
                    type
                },
                allMembers(active: true) {
                    idmember
                }
                buyingOffices {
                    idcompany
                }
                purchasingGroups {
                    idcompany
                }
                certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                exportPositions: tags(tagTypeInternalName: "${TagTypeService.EXPORT_POSITION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                    items {
                        ...fullTag
                    }
                }
                company_type_machine_name,
                logoUrl(size: "123"),
                zohoId
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,

        companyProfile: gql`
            fragment companyProfile on Company {
                idcompany,
                name,
                type,
                country {
                    name
                },
                company_type_machine_name,
                stockAvailability,
                website,
                description,
                urlname,
                logoUrl(size: "300"),
                logo {
                    file {
                        fileName,
                        filePath,
                        url
                    }
                },
                formattedAddress,
                phone,
                nbSellingPoints {
                    ...tag
                },
                yearEstablished,
                numemployees {
                    ...tag
                },
                ca {
                    ...tag
                },
                logisticsCapabilities,
                privateLabelParts,
                productionCapacity,
                privateLabel,
                linkedin,
                facebook,
                instagram,
                ecopackpolicydescription,
                citylocation {
                    latitude,
                    longitude,
                    city
                    region
                    country {
                        name
                    }
                }
                postalCode
                products(published: true) {
                    iduserproduct,
                    name,
                    url,
                    urlname,
                    logo {
                        imageUrl(size:"300")
                    },
                    published,
                    position,
                    category {
                        ...fullTag
                    },
                    company {
                        urlname
                    }
                },
                ecopackprojects{
                    idecopackproject,
                    name
                }
                categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                    items {
                        ...fullTag
                    }
                },
                customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                    items {
                        ...customTag
                    }
                },
                certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                exportPositions: tags(tagTypeInternalName: "${TagTypeService.EXPORT_POSITION_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                    items {
                        ...fullTag
                    }
                },
                files {
                    downloadLink(forceDirectDownload: false)
                    file {
                        idfile
                        fileName
                        fileExtension
                        fileSize
                    }
                },
                minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                },
                companyImages {
                    file {
                        idfile
                        fileName
                    }
                    imageUrl(size:"123")
                },
                onMembers {
                    idmember
                    firstname
                    name
                    logoUrl(size: "123", returnNull: true)
                    email
                    hasEmailVerified
                    isCompanyOwner
                }
                onBoardingFinished,
                onBoardingHasProducts,
                onBoardingHasRelationshipWithBuyer,
                onBoardingNeedlCompanyProfileCompletionStep,
                onBoardingHasCompletedNeedlCompanyProfile
                activeMemberships {
                    idsubscription
                    subscriptionpack {
                        idsubscriptionpack
                        label
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `
    };

    static queries = {
        companyProfilePage: gql`
            query companyProfilePage($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    company_type_machine_name
                    urlname
                    idcompany
                    name
                    status
                    valueProposition
                    privateLabel
                    companytype {
                        idcompanytype
                        name
                    }
                    institutions{
                        items{
                            idinstitution
                            name
                            urlname
                        }
                    }
                    logoUrl(size: "300")
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    }
                    isWabelClubMember
                    description
                    citylocation {
                        formattedAddress
                        city
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                    }
                    country {
                        name
                        tagLocation {
                            code
                        }
                    }
                    companyImages {
                        file {
                            idfile
                            fileName
                        }
                        imageUrl
                        imageUrl123: imageUrl(size: "123")
                        imageUrl300: imageUrl(size: "300")
                        imageUrl450: imageUrl(size: "450")
                    }
                    ca {
                        ...tag
                    }
                    turnoverYear
                    numemployees {
                        ...tag
                    }
                    nbSellingPoints {
                        ...tag
                    }
                    yearEstablished
                    linkedin
                    facebook
                    instagram
                    website
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    exportCountries {
                        name
                        tagLocation {
                            code
                            continent {
                                id
                                name
                            }
                        }
                    }
                    companyClients {
                        items {
                            label
                            client {
                                name
                                urlname
                            }
                        }
                    }
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    }
                    productionCapacity
                    ecopackpolicydescription
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    logisticsCapabilities
                    nbStores
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    certificationsFiles: files(filesType: "certification") {
                        downloadLink(forceDirectDownload: false)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    ecoPackProjects{
                      count
                      items{
                        idecopackproject
                        name
                        ecoProjectTags{
                          name
                          idparent
                          idecopackprojecttag
                        }
                        notes
                        packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        consistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        projectTimeLine: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        company{
                          idcompany
                        }
                      }
                    }
                    products(published: true) {
                        name
                        description
                        urlPath
                        targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        category {
                            ...fullTag
                        }
                        logoUrl(size: "123")
                    }
                    buyingOffices(active: true) {
                        logoUrl(size: "300")
                        idcompany
                        name
                        urlname
                        country {
                            tagLocation {
                                code
                                backgroundImage(size: "450")
                            }
                            name
                        }
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450")
                                }
                                name
                            }
                        }
                    }
                    purchasingGroupStoresCountryDetails {
                        countryName
                        country {
                            name
                        }
                        hyper
                        super
                        convenience
                        distributionCenter
                        warehouse
                        pointOfSales
                        cashAndCarry
                        banners {
                            name
                        }
                        hasBanners
                        total
                    }
                    purchasingGroups {
                        logoUrl(size: "300")
                        idcompany
                        name
                        urlname
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450")
                                }
                                name
                            }
                        }
                    }
                    countOnMembers
                    parentCompany {
                        idcompany
                        name
                        company_type_machine_name
                        urlname
                    }
                    stockAvailability,
                    stockAvailabilityLastUpdate
                    activeAndAlreadyLoggedMembers {
                        count
                    }
                    companyVideos {
                        items {
                            id
                            name
                            url
                        }
                    }
                    countriesNumber
                    bannersNumber
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `,

        companyInformationRelatedToMe: gql`
            query companyInformationRelatedToMe($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    hasBuyerForCurrentMemberCategories
                }
            }
        `,
        getMyCompanyProfileSubmissionsWithBuyerCompany: gql`
            query Company($buyerCompanyId: Int!){
              myCompanyProfileSubmissionsWithBuyerCompany(buyerCompanyId:$buyerCompanyId){
                  items{
                        idcompanyprofilesubmission
                        profileDescription
                        profileStep
                        profileTitle
                        profileStatus
                        updatedAt
                        createdAt
                        createdByMember{
                          fullname
                        }
                        message{
                          idprivatemessage
                          thread{
                            idthread
                          }
                        }
                        createdByCompany{
                          name
                          idcompany
                          urlname
                          logoUrl(size: "123")
                          country{
                              tagLocation {
                                  code
                              }
                            name
                          }
                        }
                        createdForCompany{
                          name
                          idcompany
                          urlname
                          logoUrl(size: "123")
                          citylocation{
                              country {
                                name
                              }
                              city
                          }
                          country{
                              tagLocation {
                                  code
                              }
                            name
                          }
                        }
                        isFeaturedProducts
                        files{
                          idfile
                          fileName
                          filePath
                          fileSize
                          fileExtension
                        }
                        companyProfileDiagnosisClients{
                          items{
                            idcompanyprofilediagnosisclient
                            company{
                                idcompany
                                name
                                logoUrl(size: "123")
                                country {
                                    name
                                }
                            }
                          }
                        }
                        isExportingToBuyerCountry
                        companyProfileFeaturedProducts{
                            items{
                                userproduct{
                                    iduserproduct
                                    name
                                    published
                                    category {
                                        name
                                        parent {
                                            parent {
                                                name
                                            }
                                        }
                                    }
                                    logoUrl(size: "123")
                                }
                            }
                        }
                        companyProfileExportingCountries{
                          items{
                            idcompanyprofileexportingcountry
                            country{
                              id
                              name
                                tagLocation {
                                    backgroundImage
                                    code
                                    nbPurchasingGroups
                                    nbImporters
                                    nbCoPackers
                                    continent {
                                        id
                                        name
                                    }
                                }
                            }
                          }
                        }
                        categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                            items{
                                id
                                name
                            }
                        }
                      }
                    }
            }
        `,
        siblingsCompanies: gql`
            query SiblingsCompanies($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    buyingOffices(active: true) {
                        logoUrl(size: "123"),
                        idcompany,
                        name,
                        urlname,
                        countOnMembers
                        country {
                            tagLocation {
                                code
                                backgroundImage(size: "450"),
                            }
                            name
                        },
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450"),
                                }
                                name
                            }
                        }
                    }
                }
            }
        `,

        companyType: gql`
            query CompanyType($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    idcompany,
                    company_type_machine_name,
                    urlname,
                    name
                }
            }
        `,

        companyInfoPrivateMessages: gql`
            query Company($idcompany: Int!) {
                company(id: $idcompany) {
                    idcompany,
                    name,
                    citylocation {
                        country {
                            name,
                            tagLocation {
                                code
                            }
                        }
                    },
                    logoUrl(size:"73"),
                    companytype {
                        idcompanytype
                    }
                }
            }
        `,

        findCompaniesByName: gql`
            query Company($limit: Int!, $offset: Int!, $order: String, $search: String!, $idCompanyTypes: [Int!]!) {
                findCompaniesByName(search: $search, idCompanyTypes: $idCompanyTypes, order: $order) {
                    count,
                    items(limit: $limit, offset: $offset) {
                        idcompany
                        name
                        type
                        website
                    }
                }
            }
        `,

        getEditProfileCompanyAdmin: gql`
            query Company($idcompany: Int!) {
                company(id: $idcompany) {
                    idcompany,
                    name,
                    type,
                    valueProposition,
                    activeMemberships {
                        idsubscription
                        subscriptionpack {
                            idsubscriptionpack
                            label
                        }
                    },
                    country {
                        name
                    },
                    companyClients {
                        items {
                            client {
                                idcompany
                                name
                            }
                            label
                        }
                    }
                    company_type_machine_name,
                    stockAvailability,
                    website,
                    description,
                    urlname,
                    logoUrl(size: "300"),
                    logo {
                        file {
                            fileName,
                            filePath,
                            url(size:"123"),
                            fileExtension
                            fileSize
                        }
                    },
                    formattedAddress,
                    phone,
                    nbSellingPoints {
                        ...tag
                    },
                    yearEstablished,
                    numemployees {
                        ...tag
                    },
                    ca {
                        ...tag
                    },
                    currency {
                        ...tag
                    },
                    logisticsCapabilities,
                    privateLabelParts,
                    productionCapacity,
                    privateLabel,
                    linkedin,
                    facebook,
                    instagram,
                    twitter,
                    pinterest,
                    ecopackpolicydescription,
                    citylocation {
                        latitude,
                        longitude,
                        city
                        region
                        country {
                            name
                        }
                    }
                    postalCode
                    products {
                        iduserproduct,
                        name,
                        url,
                        urlname,
                        logo {
                            imageUrl(size:"300")
                        },
                        published,
                        position,
                        category {
                            ...fullTag
                        },
                        company {
                            urlname
                        }
                        canBePublished,
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                    },
                    ecopackprojects{
                        idecopackproject,
                        name
                    }
                    categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                        items {
                            ...fullTag
                        }
                    },
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    },
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    },
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    exportPositions: tags(tagTypeInternalName: "${TagTypeService.EXPORT_POSITION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    },
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    },
                    certificationsFiles: files(filesType: "certification") {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    },
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    pointOfSales: tags(tagTypeInternalName: "${TagTypeService.POINT_OF_SALE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyCountriesDistribution {
                        items {
                            id
                            name
                        }
                    }
                    companyImages {
                        file {
                            fileName
                            fileExtension
                            filePath
                            fileSize
                            url(size:"123")
                        }
                        imageUrl(size:"123")
                    },
                    companyVideos {
                        items {
                            id
                            name
                            url
                            priority
                            company {
                                idcompany
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `,

        getSummitRegistrations: gql`
            query getSummitRegistrations($summitCode: String!) {
                me {
                    company {
                        idcompany
                        consultantCsm {
                            name
                            firstname
                            email
                        }
                        registrationsForSummit(summitCode: $summitCode) {
                            idcertaineventregistration
                            member {
                                idmember
                                name
                                firstname
                                email
                            }
                            hotel
                            nightsDates
                            badgeUrl
                        }
                        participationBySummitCode(summitCode: $summitCode) {
                            participantNumber
                        }
                        validatedMembers {
                            items {
                                idmember
                                name
                                firstname
                                email
                                status
                            }
                        }
                    }
                }
            }
        `,

        getEventRegistrations: gql`
            query getEventRegistrations($eventCode: String!) {
                me {
                    company {
                        idcompany
                        consultantCsm {
                            fullname
                            email
                            mobile
                            logoUrl(size:"123")
                        }
                        consultant {
                            email
                        }
                        registrationsForEvent(eventCode: $eventCode) {
                            idcertaineventregistration
                            member {
                                idmember
                                fullname
                                email
                                status
                            }
                            hotel
                            nightsDates
                            badgeUrl
                            attendeeType
                        }
                        participationByEventCode(eventCode: $eventCode) {
                            primaryWabelContact {
                                status
                                email
                                fullname
                                idmember
                                mobile
                                logoUrl(size:"123")
                            }
                            administrativeContact {
                                status
                                email
                                fullname
                                idmember
                            }
                            participantNumber
                            allBadgesUrl
                            certainEvent {
                                eventCode
                                name
                                dates
                                startDate
                                endDate
                                location
                                badgeTemplateUrl
                                eventFiles {
                                    url
                                    downloadLink
                                    file {
                                        description
                                        dateCreated
                                    }
                                }
                            }
                        }
                        allMembers {
                            idmember
                            fullname
                            email
                            status
                        }
                        summitsParticipation(eventCode: $eventCode) {
                            bannerUrl
                        }
                    }
                }
            }
        `,

        companyTags: gql`
            query Query($idcompany: Int!) {
                companyTags(idcompany: $idcompany) {
                    idcompanytag
                    idparent
                }
            }
        `,

        getAllMembers: gql`
            query getAllMembers {
                me {
                    company {
                        allMembers {
                            idmember
                            name
                            firstname
                            nameInitials
                            fullname
                            email
                            status
                        }
                    }
                }
            }
        `,

        similarCompanies: gql`
            query similarCompanies($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    similarCompanies {
                        name
                        urlname
                        logoUrl(size: "300")
                    },
                }
            }
        `,

        getInformationForProductRequestCompanyModal: gql`
            query getInformationForProductRequestCompanyModal($urlname: String!) {
                companyByUrlname(urlname: $urlname){
                    idcompany
                    urlname,
                    name,
                    ca {
                        ...tag
                    },
                    numemployees {
                        ...tag
                    },
                    yearEstablished,
                    privateLabelParts
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    logoUrl(size:"123")
                    isWabelClubMember
                    exportCountries {
                        name
                        tagLocation {
                            code,
                            continent {
                                name
                            }
                        }
                    }
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...tag
                        }
                    },
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    description
                    products(published: true) {
                        iduserproduct,
                        name,
                        url,
                        urlname,
                        logoUrl(size: "300"),
                        logo{
                            imageUrl(size:"300")
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        category {
                            ...fullTag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,

        relativeCompaniesInCountry: gql`
            query relativeCompaniesInCountry($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    relativeCompaniesInCountry {
                        idcompany
                        name
                        urlname
                        logoUrl(size: "300")
                        buyingOffices {
                            idcompany
                        }
                    },
                }
            }
        `,

        companiesByIds: gql`
            query companiesById($ids: [Int!]!) {
                companiesById(ids: $ids) {
                    idcompany
                    threadWithCurrentMember{
                        idthread
                    }
                    hasVideoMeetingsWithCurrentMember
                }
            }
        `,

        companyById: gql`
            query companyById($id: Int!) {
                companyById(id: $id) {
                    idcompany
                    name
                    website
                }
            }
        `,

        getCompanyProfileForEvent: gql`
            query companyProfilePage($urlname: String!, $eventCode: String!) {
                companyByUrlname(urlname: $urlname) {
                    company_type_machine_name
                    idcompany
                    name
                    urlname
                    hasParticipationForEvent(eventCode: $eventCode)
                    bookletDescription
                    citylocation {
                        city
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                    }
                    country {
                        name
                        tagLocation {
                            code
                        }
                    }
                    logoUrl(size: "123")
                    ca(eventCode: $eventCode) {
                        ...tag
                        type {
                            name
                        }
                    }
                    numemployees(eventCode: $eventCode) {
                        ...tag
                        type {
                            name
                        }
                    }
                    yearEstablished
                    linkedin
                    facebook
                    instagram
                    website
                    productionCapacity
                    logisticsCapabilities
                    nbStores
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    ecoPackProjects{
                      count
                      items{
                        idecopackproject
                        name
                        ecoProjectTags{
                          name
                          idparent
                          idecopackprojecttag
                        }
                        notes
                        packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        consistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        projectTimeLine: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        company{
                          idcompany
                        }
                      }
                    }
                    products(eventCode: $eventCode, published: true) {
                        iduserproduct
                        name
                        urlPath
                        composition
                        packaging
                        description
                        company {
                            idcompany
                            name
                        }
                        category {
                            ...fullTag
                        }
                        logoUrl(size: "300")
                        userproductsImages {
                            imageUrl(size: "300")
                        }
                        countryOrigin {
                            id
                            name
                        }
                        manufacturingCountry {
                            id
                            name
                            tagLocation {
                                code
                            }
                        }
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                    }
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    }
                    exportCountries {
                        name
                        tagLocation {
                            code
                            continent {
                                id
                                name
                            }
                        }
                    }
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    }
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    buyingOffices(active: true) {
                        country {
                            tagLocation {
                                code
                                backgroundImage(size: "450")
                            }
                            name
                        }
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450")
                                }
                                name
                            }
                        }
                    }
                    participationByEventCode(eventCode: $eventCode) {
                        sponsor {
                            image {
                                url
                            }
                            file {
                                url
                            }
                            url
                        }
                    }
                    companyClients {
                        items {
                            label
                            client {
                                name
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `,
        getCompanyProfileForOpenCatalog: gql`
            query companyProfilePage($urlname: String!, $eventCode: String!) {
                companyByUrlname(urlname: $urlname) {
                    company_type_machine_name
                    idcompany
                    name
                    isInCatalog(eventCode: $eventCode)
                    description
                    citylocation {
                        city
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                    }
                    numemployees(eventCode: $eventCode) {
                        ...tag
                    }
                    ca(eventCode: $eventCode) {
                        ...tag
                    }
                    yearEstablished
                    linkedin(eventCode: $eventCode)
                    facebook(eventCode: $eventCode)
                    instagram(eventCode: $eventCode)
                    website(eventCode: $eventCode)
                    productionCapacity(eventCode: $eventCode)
                    logisticsCapabilities(eventCode: $eventCode)
                    nbStores
                    nbSellingPoints {
                        name
                    }
                    files(eventCode: $eventCode) {
                        downloadLink(forceDirectDownload: false, eventCode: $eventCode)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    ecoPackProjects{
                      count
                      items{
                        idecopackproject
                        name
                        ecoProjectTags{
                          name
                          idparent
                          idecopackprojecttag
                        }
                        notes
                        packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        consistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        projectTimeLine: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME}") {
                            items {
                                id
                                name
                            }
                        },
                        company{
                          idcompany
                        }
                      }
                    }
                    products(eventCode: $eventCode, published: true) {
                        name
                        urlPath
                        category {
                            ...fullTag
                        }
                        logoUrl(size: "300")
                        targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                    }
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    }
                    exportCountries(eventCode: $eventCode) {
                        name
                        tagLocation {
                            code
                            continent {
                                id
                                name
                            }
                        }
                    }
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    }
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    buyingOffices(active: true) {
                        country {
                            tagLocation {
                                code
                                backgroundImage(size: "450")
                            }
                            name
                        }
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450")
                                }
                                name
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
            `,
        getMyCompanyAnalyticsDashboard: gql`
            query getMyCompanyAnalyticsDashboard($dateRange: String!) {
                me {
                    company {
                        visitsForAnalyticsDashboard(dateRange: $dateRange)
                        totalVisitsForAnalyticsDashboard(dateRange: $dateRange)
                        searchApparitionsForAnalyticsDashboard(dateRange: $dateRange)
                        totalSearchApparitionsForAnalyticsDashboard(dateRange: $dateRange)
                        top3MostViewedProductsForAnalyticsDashboard(dateRange: $dateRange)
                        overviewCountryForAnalyticsDashboard(dateRange: $dateRange)
                        overviewCompanyTypeForAnalyticsDashboard(dateRange: $dateRange)
                        overviewTurnoverForAnalyticsDashboard(dateRange: $dateRange)
                        lastInterestedVisitorsForAnalyticsDashboard(dateRange: $dateRange)
                        rankVisibilityCategoriesPercentageAnalyticsDashboard: rankVisibilityPercentageAnalyticsDashboard(dateRange: $dateRange)
                        rankVisibilityCountryPercentageAnalyticsDashboard: rankVisibilityPercentageAnalyticsDashboard(dateRange: $dateRange, forCategories: false)
                        products(published: true) {
                          iduserproduct
                          name
                          logoUrl(size: "300")
                        }
                        hasActiveMembersLastMonthForAnalyticsDashboard
                        productCategoriesInRedisForMyMarketAnalytics(dateRange: $dateRange){
                            items {
                                ...fullTag
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,
        getMyCompanyForMyMarketAnalyticsDashboard: gql`
            query getMyCompanyForMyMarketAnalyticsDashboard($dateRange: String!,$selectedCategory2Id: String!,$selectedCategories3Ids: [String!]!) {
                me {
                    company {
                        marketByCompanyTypeForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                        marketByCountryForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                        marketByTurnoverForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                        marketMostSearchedProductsCategoriesForAnalyticsDashboard(dateRange: $dateRange , selectedCategories3Ids: $selectedCategories3Ids)
                    }
                }
            }
        `,
        getMyCompanyForMyMarketAnalyticsOverView: gql`
            query getMyCompanyForMyMarketAnalyticsDashboard($dateRange: String!,$selectedCategory2Id: String!) {
                me {
                    company {
                        marketByCompanyTypeForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                        marketByCountryForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                        marketByTurnoverForAnalyticsDashboard(dateRange: $dateRange , selectedCategory2Id: $selectedCategory2Id)
                    }
                }
            }
        `,
        getMyCompanyForMyMarketAnalyticsMostSearched: gql`
            query getMyCompanyForMyMarketAnalyticsDashboard($dateRange: String!,$selectedCategories3Ids: [String!]!) {
                me {
                    company {
                        marketMostSearchedProductsCategoriesForAnalyticsDashboard(dateRange: $dateRange , selectedCategories3Ids: $selectedCategories3Ids)
                    }
                }
            }
        `,

        getCompanyProfileForCompanyDetailsModal: gql`
            query companyProfilePage($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    company_type_machine_name
                    idcompany
                    name
                    description
                    urlname
                    citylocation {
                        city
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                    }
                    country {
                        name
                        tagLocation {
                            code
                        }
                    }
                    logoUrl(size: "123")
                    ca {
                        ...tag
                        type {
                            name
                        }
                    }
                    numemployees {
                        ...tag
                        type {
                            name
                        }
                    }
                    yearEstablished
                    linkedin
                    facebook
                    instagram
                    website
                    productionCapacity
                    logisticsCapabilities
                    nbStores
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    ecoPackProjects{
                        count
                        items{
                            idecopackproject
                            name
                            ecoProjectTags{
                                name
                                idparent
                                idecopackprojecttag
                            }
                            notes
                            packagingPurpose: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PURPOSE_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            consistency: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_CONSISTENCY_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            projectTimeLine: tags(tagTypeInternalName: "${TagTypeService.ECO_PACKAGING_PROJECT_TIMELINE_INTERNAL_NAME}") {
                                items {
                                    id
                                    name
                                }
                            },
                            company{
                                idcompany
                            }
                        }
                    }
                    products(published: true) {
                        iduserproduct
                        name
                        urlPath
                        composition
                        packaging
                        description
                        category {
                            ...fullTag
                        }
                        logoUrl(size: "300")
                        userproductsImages {
                            imageUrl(size: "300")
                        }
                        countryOrigin {
                            id
                            name
                        }
                        manufacturingCountry {
                            id
                            name
                            tagLocation {
                                code
                            }
                        }
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                    }
                    rootCategories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}", onlyLevel1: true) {
                        items {
                            ...fullTag
                        }
                    }
                    exportCountries {
                        name
                        tagLocation {
                            code
                            continent {
                                id
                                name
                            }
                        }
                    }
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            ...tag
                        }
                    }
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    }
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    buyingOffices(active: true) {
                        country {
                            tagLocation {
                                code
                                backgroundImage(size: "450")
                            }
                            name
                        }
                        citylocation {
                            country {
                                tagLocation {
                                    code
                                    backgroundImage(size: "450")
                                }
                                name
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.customTag}
        `,
        getMyCompanyProductionStatus: gql`
            query getMyCompanyProductionStatus {
                me {
                    company {
                        stockAvailability
                    }
                }
            }
        `,
        getInformationForVideoMeetingRequestButton: gql`
            query getInformationForVideoMeetingRequestButton($idcompany: Int!) {
                company(id: $idcompany) {
                    hasPendingVideoMeetingsWithCurrentMember
                    activeAndAlreadyLoggedMembers {
                        count
                    }
                }
            }
        `,
        getInformationForVideoMeetingRequestModal: gql`
            query getInformationForVideoMeetingRequestModal($idcompany: Int!) {
                company(id: $idcompany) {
                    name
                }
            }
        `,
        getInformationForVideoMeetingInviteColleaguesModal: gql`
            query getInformationForVideoMeetingInviteColleaguesModal($idcompany: Int!) {
                company(id: $idcompany) {
                    allMembers {
                        idmember
                        status
                        logoUrl(size:"123", returnNull: true)
                        fullname
                        nameInitials
                        email
                        jobtitle
                    }
                }
            }
        `,
        companyProfileDetails: gql`
            query companyProfilePage($urlname: String!, $businessMappingPriority: BusinessMappingPriorityInput) {
                companyByUrlname(urlname: $urlname) {
                    description
                    linkedin
                    facebook
                    instagram
                    pinterest
                    twitter
                    website
                    formattedAddress
                    files(businessMappingPriority: $businessMappingPriority) {
                        downloadLink(forceDirectDownload: false)
                        file {
                            idfile
                            fileName
                            fileExtension
                            fileSize
                        }
                    }
                    pointOfSales: tags(tagTypeInternalName: "${TagTypeService.POINT_OF_SALE_INTERNAL_NAME}") {
                        items {
                            name
                        }
                    },
                }
            }
        `,
        companyProducts: gql`
            query companyProfilePage($urlname: String!, $businessMappingPriority: BusinessMappingPriorityInput) {
                companyByUrlname(urlname: $urlname) {
                     products(published: true, businessMappingPriority: $businessMappingPriority) {
                        iduserproduct
                        name
                        urlPath
                        composition
                        packaging
                        description
                        company {
                            idcompany
                            name
                        }
                        category {
                            ...fullTag
                        }
                        logoUrl(size: "300")
                        userproductsImages {
                            imageUrl(size: "300")
                        }
                        countryOrigin {
                            id
                            name
                        }
                        manufacturingCountry {
                            id
                            name
                            tagLocation {
                                code
                            }
                        }
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        targetConsumers: tags(tagTypeInternalName: "${TagTypeService.TARGET_CONSUMER_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        rangeOfProducts: tags(tagTypeInternalName: "${TagTypeService.RANGE_OF_PRODUCT_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        nutritionalInformations: tags(tagTypeInternalName: "${TagTypeService.NUTRITIONAL_INFORMATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        qualityLabels: tags(tagTypeInternalName: "${TagTypeService.QUALITY_LABEL_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        exportCertifications: tags(tagTypeInternalName: "${TagTypeService.EXPORT_CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.fullTag}
        `,
        companyGeographicalBreakdown: gql`
            query companyProfilePage($urlname: String!) {
                companyByUrlname(urlname: $urlname) {
                    purchasingGroupStoresCountryDetails {
                        countryName
                        country {
                            name
                            tagLocation {
                                code
                            }
                        }
                        banners {
                            name
                        }
                        hasBanners
                        total
                    }
                }
            }
        `,
        companyCountriesOfActivity: gql`
            query companyProfilePage($urlname: String!, $businessMappingPriority: BusinessMappingPriorityInput) {
                companyByUrlname(urlname: $urlname) {
                    exportCountries(businessMappingPriority: $businessMappingPriority){
                        name
                        tagLocation {
                            code
                            continent {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `,
        getRemainingProductRequestNumberForMyCompany: gql`
            query getRemainingProductRequestNumberForMyCompany {
                me {
                    company {
                        remainingProductRequests
                        activeMemberships {
                            resetDate
                            endDate
                            subscriptionpack {
                                label
                                monthlyProductRequestsNumber
                            }
                        }
                    }
                }
            }
        `,
        getMySourcingRelationships: gql`
            query getMySourcingRelationships {
                mySourcingRelationships {
                    count
                    items {
                        idcompany
                        name
                        urlname
                        productionCapacity
                        logisticsCapabilities
                        turnoverYear
                        country {
                            id
                            name
                        }
                        ca {
                            ...tag
                        }
                        activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        },
                        categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        productsInFavorites {
                            items {
                                name
                            }
                        }
                        certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        productAttributes {
                            items {
                                ...tag
                            }
                        }
                        priceRanges: companyProductsTags(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                        originsOfMainIngredients {
                            items {
                                id
                                name
                            }
                        }
                        minimumOrderQuantities: tags(tagTypeInternalName: "${TagTypeService.MOQ_INTERNAL_NAME}") {
                            items {
                                ...tag
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,
        getActiveProductRequestOpportunities: gql`
            query getActiveProductRequestOpportunities {
                me {
                    company {
                        activeProductRequestOpportunities {
                            count
                        }
                    }
                }
            }
        `,
    };

    static mutations = {
        updateCompanyEcoPolicy: gql`
            mutation Company(
                $description: String!
                $ecofriendlyoptions:[String!]!
                ){
                updateCompanyEcoPolicy(
                    description:$description,
                    ecofriendlyoptions:$ecofriendlyoptions
                    ){
                        idcompany,
                        ecopackpolicydescription,
                        ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                            items {
                                ...tag
                            }
                        },
                        onBoardingCompletedEcoPackPolicy
                    }
                }
                ${TagTypeGraphql.fragments.tag}

        `,

        updateCompanyFactAndFigures: gql`
            mutation updateCompanyFactAndFigures(
                $idcompany: Int!,
                $yearEstablished: Int,
                $numberEmployees: Int,
                $turnover: Int,
                $turnoverYear: Int,
                $currency: Int,
                $nbSellingPoints: Int,
                $pointsOfSaleTypes: [Int!],
                $countriesNumber: Int,
                $bannersNumber: Int
            ) {
                updateCompanyFactAndFigures(idcompany: $idcompany,
                    yearEstablished: $yearEstablished,
                    numberEmployees: $numberEmployees,
                    turnover: $turnover,
                    turnoverYear: $turnoverYear,
                    currency: $currency,
                    nbSellingPoints: $nbSellingPoints,
                    pointsOfSaleTypes: $pointsOfSaleTypes,
                    countriesNumber: $countriesNumber,
                    bannersNumber: $bannersNumber
                    )
                {
                    idcompany
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                    ca {
                        ...tag
                    },
                    numemployees {
                        ...tag
                    },
                    yearEstablished,
                    turnoverYear,
                    currency {
                        ...tag
                    },
                    nbSellingPoints {
                        ...tag
                    },
                    pointOfSales: tags(tagTypeInternalName: "${TagTypeService.POINT_OF_SALE_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                    countriesNumber
                    bannersNumber
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,

        updateCompanyDistribution: gql`
            mutation updateCompanyDistribution(
                $idcompany: Int!,
                $logisticCapacity: String
                $distributionChannels: [Int!],
                $exportRegions: [Int!],
            ) {
                updateCompanyDistribution(idcompany: $idcompany,
                    logisticCapacity: $logisticCapacity,
                    distributionChannels: $distributionChannels,
                    positionsExport: $exportRegions)
                {
                    idcompany,
                    logisticsCapabilities,
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                    companyDistributionChannelsReference: tags(tagTypeInternalName: "${TagTypeService.DISTRIBUTION_CHANNEL_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    exportPositions: tags(tagTypeInternalName: "${TagTypeService.EXPORT_POSITION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,

        sendMailPremiumRequest: gql`
            mutation Company($idCompanyTarget: Int!, $sourcePage: String, $membershipRequested: String!, $idSourcingNeed: Int, $idSourcingRequest: Int) {
                sendMailPremiumRequest(idCompanyTarget: $idCompanyTarget, sourcePage: $sourcePage, membershipRequested: $membershipRequested, idSourcingNeed: $idSourcingNeed, idSourcingRequest: $idSourcingRequest) {
                    idcompany
                }
            }
        `,

        saveMemberCompletedDataConfirmation: gql`
            mutation Company($idcompany: Int!, $userConfirmation: Boolean!,$typeOfData: String!) {
                saveMemberCompletedDataConfirmation(idcompany: $idcompany, userConfirmation: $userConfirmation, typeOfData: $typeOfData) {
                    idcompany
                    hasAddedAllProducts
                    hasAddedAllClients
                    hasAddedAllEcoPackProjects
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                }
            }
        `,

        createAdministrativeContact: gql`
            mutation createAdministrativeContact($idcompany: Int!, $eventCode: String!, $firstName: String!, $lastName: String!, $email: String!) {
                createAdministrativeContact(idcompany: $idcompany, eventCode: $eventCode, firstName: $firstName, lastName: $lastName, email: $email) {
                    administrativeContact {
                        status
                        email
                        fullname
                        idmember
                    }
                }
            }
        `,

        editAdministrativeContact: gql`
            mutation editAdministrativeContact($idcompany: Int!, $eventCode: String!, $idmember: Int!) {
                editAdministrativeContact(idcompany: $idcompany, eventCode: $eventCode, idmember: $idmember) {
                    administrativeContact {
                        status
                        email
                        fullname
                        idmember
                    }
                }
            }
        `,

        updateCompanyInformation: gql`
            mutation Company(
            $idcompany: Int!,
            $website: String,
            $phone: String,
            $description: String,
            $formatted_address: String,
            $logo: [FileToUploadInput!],
            $country_name: String,
            $region_name: String,
            $city_name: String,
            $postal_code: String,
            $valueProposition: String,
            $stockAvailability: String
            ) {
                updateCompanyInformation(
                    idcompany: $idcompany,
                    website: $website,
                    phone: $phone,
                    description: $description,
                    formatted_address: $formatted_address,
                    logo: $logo,
                    country_name: $country_name,
                    region_name: $region_name,
                    city_name: $city_name,
                    postal_code: $postal_code,
                    valueProposition: $valueProposition,
                    stockAvailability: $stockAvailability
                ) {
                    idcompany
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                    logo {
                        file {
                            fileName
                            filePath
                            url
                        }
                    }
                    logoUrl(size: "123")
                    stockAvailability
                }
            }
        `,

        updateCompanyMarketing: gql`
            mutation updateCompanyMarketing($idcompany: Int!, $pictures: [FileToUploadInput!]!, $documents: [FileToUploadInput!]!, $linkedin: String, $facebook: String, $instagram: String, $twitter: String, $pinterest: String, $companyVideos: [CompanyVideoInput!]!) {
                updateCompanyMarketing(idcompany: $idcompany, pictures: $pictures, documents: $documents, linkedin: $linkedin, facebook: $facebook, instagram: $instagram, twitter: $twitter, pinterest: $pinterest, companyVideos: $companyVideos) {
                    idcompany,
                    files {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    },
                    companyImages {
                        file {
                            fileName
                            fileExtension
                            filePath
                            fileSize
                            url(size:"123")
                        }
                        imageUrl(size:"123")
                    },
                    companyVideos {
                        items {
                            id
                            name
                            url
                            priority
                            company {
                                idcompany
                            }
                        }
                    },
                    linkedin,
                    facebook,
                    instagram,
                    twitter,
                    pinterest,
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                }
            }
        `,

        updateCompanyEcoPackPolicy: gql`
            mutation updateCompanyEcoPackPolicy($idcompany: Int!, $description: String, $features: [Int!]!) {
                updateCompanyEcoPackPolicy(idcompany: $idcompany, description: $description, features: $features) {
                    idcompany
                    ecopackpolicydescription
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                    ecoPackPolicyFeatures: tags(tagTypeInternalName: "${TagTypeService.ECO_PACK_POLICY_FEATURES}") {
                        items {
                            id
                            name
                        }
                    }
                }
            }
        `,

        updateCompanyProduction: gql`
            mutation updateCompanyProduction($idcompany: Int!, $moq: [Int!], $countries: [Int!], $categories: [Int!], $productionCapacity: String, $privateLabelParts: Int) {
                updateCompanyProduction(idcompany: $idcompany, moq: $moq, countries: $countries, categories: $categories, productionCapacity: $productionCapacity, privateLabelParts: $privateLabelParts) {
                    idcompany
                    hasAllRequiredProfileFields
                    activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,

        updateCompanyCertifications: gql`
            mutation updateCompanyCertifications(
                $idcompany: Int!,
                $certifications: [Int!],
                $customCertifications: [String!],
                $certificationsDocuments: [FileToUploadInput!]!,
            ) {
                updateCompanyCertifications(
                    idcompany: $idcompany,
                    certifications: $certifications,
                    customCertifications: $customCertifications,
                    certificationsDocuments: $certificationsDocuments
                ) {
                    idcompany,
                    customCertifications: customTags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...customTag
                        }
                    },
                    certifications: tags(tagTypeInternalName: "${TagTypeService.CERTIFICATION_INTERNAL_NAME}") {
                        items {
                            ...tag
                        }
                    },
                    certificationsFiles: files(filesType: "certification") {
                        downloadLink(forceDirectDownload: false)
                        file {
                            fileName
                            filePath
                            fileExtension
                            fileSize
                            url
                        }
                    }
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                }
            }
            ${TagTypeGraphql.fragments.tag}
            ${TagTypeGraphql.fragments.customTag}
        `,

        updateCompanyClients: gql`
            mutation updateCompanyClients(
                $idcompany: Int!,
                $clients: [CompanyClientInput!]!,
                $userConfirmation: Boolean
            ) {
                updateCompanyClients(
                    idcompany: $idcompany,
                    clients: $clients,
                    userConfirmation: $userConfirmation
                ) {
                    idcompany,
                    companyClients {
                        items {
                            client {
                                idcompany
                                name
                            }
                            label
                        }
                    }
                    hasAllRequiredProfileFields
                    companyRequiredInfoScore {
                        name,
                        value,
                        children {
                            name,
                            value
                        },
                    }
                    companyRewardScore {
                        name,
                        value,
                        weight,
                        children {
                            name,
                            value,
                            weight,
                        },
                    },
                }
            }
        `,

        importCompanyMissingImages: gql`
            mutation NdlImportCompanyMissingImages($ids: [Int!]!, $excludeProducts: Boolean!) {
                importCompanyMissingImages(ids: $ids, excludeProducts: $excludeProducts) {
                    idcompany
                }
            }
        `
    };
}
